<template>
  <div class="htmleaf-container">
    <header class="htmleaf-header">
      <h1>
        {{ artObj.Title }}
        <span>{{ artObj.Author }} {{ artObj.CreationTime }}</span>
      </h1>
    </header>
    <div class="content">
      <!-- <h2>{{ artObj.Title }}</h2> -->
      <div class="baguetteBoxTwo gallery">
        <a :href="item" v-for="(item, index) in artObj.ImgSrc" :key="index">
          <img :src="item" alt="" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { queryArtExhibitionDetail } from "@/api/art";
import img from "@/assets/images/artbackground.jpg";
export default {
  name: "index",
  components: {},
  computed: {},
  watch: {},
  data() {
    return {
      artObj: {},
      imgsrc: "",
      img,
      id:0,
    };
  },
  created() {
    this.id = this.$route.query.id;
    // this.artObj = JSON.parse(localStorage.getItem("H5ArtList"));
    // console.log(this.artObj);
  },
  mounted() {
    this.gettQueryArtExhibitionDetail();
  },
  methods: {
    init() {
      let arr = [
        "https://api.pulijiaoyu.org.cn/js/art/baguetteBox.js",
        "https://api.pulijiaoyu.org.cn/js/art/highlight.min.js",
      ];
      arr.forEach((item) => {
        let script = document.createElement("script");
        script.setAttribute("type", "text/javascript");
        script.setAttribute("src", item);
        document.getElementsByTagName("head")[0].appendChild(script);
      });
      let script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.text = 'window.onload = function() {   baguetteBox.run(".baguetteBoxTwo");if (typeof oldIE === "undefined" && Object.keys) { hljs.initHighlighting();}}';
      document.getElementsByTagName("head")[0].appendChild(script2);
    },
    //获取数据
    async gettQueryArtExhibitionDetail() {
      const res = await queryArtExhibitionDetail(this.id);
      this.artObj = res.response;
      this.init()
    },
  },
};
</script>
<style scoped>
@import "../../../assets/css/art/normalize.css";
@import "../../../assets/css/art/htmleaf-demo.css";
@import "../../../assets/css/art/baguetteBox.css";

h1,
h2,
h3,
footer,
.gallery {
  text-align: center;
}

@media (max-width: 480px) {
  pre {
    max-width: 98%;
  }
}

header,
footer {
  display: block;
  padding: 0.2em 0;
  background-color: #555;
  box-shadow: 0 0 8px #222;
}

footer {
  margin-top: 1.5em;
}

.content {
  max-width: 1160px;
  margin: 0 auto;
}

.gallery:after {
  content: "";
  display: block;
  height: 2px;
  margin: 0.5em 0 1.4em;
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 0%,
    rgba(77, 77, 77, 1) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(77, 77, 77, 1) 50%,
    rgba(0, 0, 0, 0) 100%
  );
}

.gallery img {
  height: 100%;
}

.gallery a {
  /* width: 240px; */
  height: 200px;
  display: inline-block;
  overflow: hidden;
  margin: 4px 6px;
  box-shadow: 0 0 4px -1px #000;
}

.ribbon > img {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
}

@media (max-width: 768px) {
  .sm-hidden {
    display: none;
  }
}

/* Highlight.js Tomorrow Night style */
.hljs-comment,
.hljs-quote {
  color: #969896;
}
.hljs-deletion,
.hljs-name,
.hljs-regexp,
.hljs-selector-class,
.hljs-selector-id,
.hljs-tag,
.hljs-template-variable,
.hljs-variable {
  color: #c66;
}
.hljs-tag {
  color: #f8f8f2;
}
.hljs-built_in,
.hljs-builtin-name,
.hljs-link,
.hljs-literal,
.hljs-meta,
.hljs-number,
.hljs-params,
.hljs-type {
  color: #de935f;
}
.hljs-attr {
  color: #f0c674;
}
.hljs-addition,
.hljs-bullet,
.hljs-string,
.hljs-symbol {
  color: #b5bd68;
}
.hljs-section,
.hljs-title {
  color: #81a2be;
}
.hljs-keyword,
.hljs-selector-tag {
  color: #b294bb;
}
.hljs {
  display: block;
  overflow-x: auto;
  background: #1d1f21;
  color: #c5c8c6;
  padding: 0.8em;
}
.hljs-emphasis {
  font-style: italic;
}
.hljs-strong {
  font-weight: 700;
}
</style>
